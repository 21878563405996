import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { addMinutes, format, parseISO, subDays } from 'date-fns'
import dayjs from 'dayjs'

import {
    DATE_FORMAT,
    DATE_ONLY_FORMAT,
    TIMESTAMP_FORMAT,
    TIMESTAMP_ISO_FORMAT,
    DATE_TIMESTAMP_FORMAT,
} from './appConstants'

export const emptyToNull = (value) => isEmpty(value) ? null : value

export const isEmpty = (value) => {
    return (
        value === undefined ||
        value === null ||
        (typeof (value) === 'number' && isNaN(value)) ||
        (typeof (value) === 'string' && value.trim() === '')
    )
}

export const isPresent = (value) => !isEmpty(value)

export const squashArr = (arrays) => {
    if (isEmpty(arrays)) return null
    if (arrays.every(isEmpty)) return null

    return arrays.flat().filter(isPresent)
}

export const validDateLength = (value) => {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/

    return dateRegex.test(value)
}

export const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date)
}

export const datePickerSanitizer = (date) => {
    if (isEmpty(date)) return date

    const separator = date[4]

    return date.split(separator).join('/')
}

export const dateSlashesToDashes = (date) => {
    if (isEmpty(date)) return date

    return date.split('/').join('-')
}

export const currentDate = () => {
    const d = new Date()
    return d.toISOString().split('T')[0]
}

export const currentDateMinusDays = (days) => {
    const d = subDays(new Date(), days)
    return d.toISOString().split('T')[0]
}

export const dateString = (date) => {
    return date.toISOString().split('T')[0].split('-').join('/')
}

export const dateTimeLocalToUtc = (value) => {
    if (isEmpty(value)) return value

    const date = new Date(value)

    return format(addMinutes(date, date.getTimezoneOffset()), TIMESTAMP_FORMAT)
}

export const addTimezoneOffsetToDate = (date) => {
    const now = new Date()
    const offset = now.getTimezoneOffset()

    return dayjs(date).add(offset, 'minute')
}

export const dateTimeUTCToISO = (value) => {
    if (isEmpty(value)) return value

    const parsed = parseISO(value)

    return format(parsed, TIMESTAMP_ISO_FORMAT)
}

export const dateTimeToDate = (value) => {
    if (isEmpty(value)) return value

    const parsed = parseISO(value)

    return format(parsed, DATE_TIMESTAMP_FORMAT)
}

export const sanitizeDate = (value) => {
    try {
        if (isEmpty(value)) return value

        const parsed = parseISO(value)

        return format(parsed, DATE_FORMAT)
    } catch (error) {
        return value
    }
}

export const sanitizeDateOnly = (value) => {
    try {
        if (isEmpty(value)) return value

        const parsed = parseISO(value)

        return format(parsed, DATE_ONLY_FORMAT)
    } catch (error) {
        return value
    }
}

export const sanitizedDateString = (value) => {
    try {
        if (isEmpty(value)) return value

        const parsed = parseISO(value)

        return parsed.toISOString().split('T')[0]
    } catch (error) {
        return value
    }
}

export const checkImageNotNull = (image) => {
    return image !== null && image !== undefined
}

export const parseNullInt = (value) => {
    try {
        if (value.trim() === '') return null

        const result = parseInt(value)

        if (isNaN(result)) return null

        return result
    } catch (error) {
        return null
    }
}

export const parseNullFloat = (value) => {
    try {
        if (value[value.length - 1] === '.') return value
        if (isNaN(value)) return null
        if (value.trim() === '') return null

        return parseFloat(value)
    } catch (error) {
        return null
    }
}

export const isValidJSON = (string) => {
    try {
        JSON.parse(string)
        return true
    } catch (error) {
        return false
    }
}

export const sanitizeFilename = (filename) => {
    const baseName = filename.replace(/\s/g, '-').toLowerCase()

    // Date.now() will return int millisecond count since Epoch, as opposed to using RNG to create a hash.
    // It will be predictable, however, it is unlikely to produce duplicates via RNG.
    // Using double underscore so we can use split to obtain the base filename if needed
    return `${Date.now()}__${baseName}`
}

export const fmtCoords = (latitude, longitude) => {
    if (isEmpty(latitude) || isEmpty(longitude)) return { lat: null, lng: null }

    const lat = parseFloat(latitude).toFixed(3)
    const lng = parseFloat(longitude).toFixed(3)

    return { lat, lng }
}

export const readFileToBase64 = async (file) => {
    return await new Promise((resolve, reject) => {
        try {
            const reader = new FileReader()

            reader.onloadend = () => {
                const base64String = reader
                    .result
                    .replace('data:', '')
                    .replace(/^.+,/, '')

                resolve(base64String)
            }

            reader.readAsDataURL(file)
        } catch (error) {
            reject(error)
        }
    })
}

export const readFileToText = async (file) => {
    return await new Promise((resolve, reject) => {
        try {
            const reader = new FileReader()

            reader.onloadend = () => {
                const data = reader.result

                resolve(data)
            }

            reader.readAsText(file)
        } catch (error) {
            reject(error)
        }
    })
}

export const parseCSVToArr = async (file) => {
    const text = await readFileToText(file)
    const textArr = text.split('\n')
    // Removes outer quotes
    const codeArr = textArr.map((code) => code.slice(1, code.length - 2))
    // Removes empty string at end of array
    codeArr.pop()
    return codeArr
}

export function validatePhone(num) {
    if (emptyToNull(num) === null) return true
    const strippedNumber = num.replace(/\D/g, '')
    const noExtension = /^\d{10}$/
    const withExtension = /^(\d{12,16})$/

    const valid = !!strippedNumber.match(noExtension) || !!strippedNumber.match(withExtension)

    return valid
}

export function fmtPhone(input) {
    // returns (###) ###-#### || (###) ###-#### ext ####
    // ext can be anywhere from 2 - 6 digits
    if (emptyToNull(input) === null) return ''

    // strips number to just digits
    input = input.replace(/\D/g, '')

    const length = input.length
    if (length < 4) return input
    if (length >= 4) input = `(${input.slice(0, 3)}) ${input.slice(3)}`
    if (length > 6) input = `${input.slice(0, 9)}-${input.slice(9)}`
    if (length > 10) input = `${input.slice(0, 14)} ext ${input.slice(14, 20)}`
    return input
}

export const fmtNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const capitalize = (string) => {
    if (isEmpty(string)) return ''

    return string.slice(0, 1).toUpperCase() + string.slice(1)
}

export const lastXChar = (string, num) => {
    if (isPresent(string)) {
        const actualString = string?.toString()

        if (actualString.length > num) {
            const subString = actualString.substring((actualString.length - num))

            return `...\u00A0${subString}`
        }
    }

    return string
}

export const bundleImageData = async (file) => {
    const name = sanitizeFilename(file.name)
    const data = await readFileToBase64(file)

    return { name, data }
}

export const bundleFileData = async (file) => {
    const name = sanitizeFilename(file.name)
    const data = await readFileToBase64(file)

    return { name, data }
}

export const yMax = (data) => {
    return Math.floor(Math.max(...data.map((o) => o.y)) * 1.1)
}

export const yMaxMulti = (arr) => {
    if (
        !arr ||
        arr?.length === 0
    ) return 1

    const values = arr?.map((set) => set?.data?.map((v) => v.y || 0)).flat()

    const max = Math.max(...values)

    return max === 0 ? 1 : Math.floor(max * 1.1)
}

export const yTicksIntegerHelper = (max) => {
    return Math.min(5, Math.floor(max))
}

export const xTicks = (data, freq) => {
    if (isEmpty(data)) return

    return data.map((v, i) => i % freq !== 0 ? '' : v.x)
}

export const xTicksMulti = (arr, freq) => {
    if (isEmpty(arr)) return

    const xTicks = arr.slice(0).map((o) => {
        o.data = o.data.map((v, i) => i % freq !== 0 ? { x: '', y: v.y } : v)

        return o
    })

    return xTicks
}

export const lineChartHelper = (data) => {
    return data.map(({ label, value }) => ({ x: label, y: value }))
}

export const mapChartHelper = (data) => {
    return data.map(({ id }) => ({ id: id, value: 1 }))
}

export const chartDataHelper = (id, color, data) => {
    data = lineChartHelper(data)

    return [{ id, color, data }]
}

export const dateTimeLocalToUtcDate = (value) => {
    if (isEmpty(value)) return value

    const date = new Date(value)

    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
}

export const tickLabelSpacingHelper = (data) => {
    const valuesToShow = data?.map((v, i) => i === 0 || i === data.length - 1 ? dateTimeLocalToUtcDate(new Date(v.label)) : '')

    return valuesToShow
}

export const not = (a, b) => {
    return a.filter((value) => b.indexOf(value) === -1)
}

export const intersection = (a, b) => {
    return a.filter((value) => b.indexOf(value) !== -1)
}

export const union = (a, b) => {
    return [...a, ...not(b, a)]
}

export const isProduction = () => {
    return window.location.href.includes('admin.vizerapp.com')
}

export const areAny = (objects, fields, qualifier) => {
    if (!Array.isArray(objects)) return false
    if (!Array.isArray(fields)) return false
    if (fields.length === 0 || objects.length === 0) return false

    const results = fields.map((field) => {
        const result = {}

        const has_some = objects.some((s) => {
            if (isEmpty(s)) return false
            if (isEmpty(s[field])) return false
            // eslint-disable-next-line eqeqeq
            return s[field] == qualifier
        })

        result[field] = has_some

        return result
    })

    return Object.assign({}, ...results)
}

export const areAll = (objects, fields, qualifier) => {
    if (!Array.isArray(objects)) return false
    if (!Array.isArray(fields)) return false
    if (fields.length === 0 || objects.length === 0) return false

    const results = fields.map((field) => {
        const result = {}

        const has_some = objects.every((s) => {
            if (isEmpty(s)) return false
            if (isEmpty(s[field])) return false
            // eslint-disable-next-line eqeqeq
            return s[field] == qualifier
        })

        result[field] = has_some

        return result
    })

    return Object.assign({}, ...results)
}

export const reduceQueries = (results) => {
    if (!Array.isArray(results)) return false
    if (results.length === 0) return false

    const data = results.map((result) => result.data)
    const payloads = data.map((data) => data?.payload)

    const success = areAll(data, ['success'], true).success
    const connectionError = squashArr(results.map((result) => result?.error))

    const notices = squashArr(data.map((data) => data?.notices))
    const warnings = squashArr(data.map((data) => data?.warnings))
    const errors = squashArr(data.map((data) => data?.errors))

    // NOTE(drewbrad4): We can move this up a layer if need be for a little more customization
    const listOfIs = ['isError', 'isLoading', 'isFetching', 'isRefetching', 'isInitialLoading']
    const isResults = areAny(results, listOfIs, true)

    return {
        payloads,
        success, connectionError,
        notices, warnings, errors,
        isResults,
    }
}

export function searchObj(obj, query) {
    for (const key in obj) { // eslint-disable-line
        if (key === query && obj[key]) return true

        if (obj[key] && typeof obj[key] === 'object') {
            const found = searchObj(obj[key], query)
            if (found) return true
        }
    }

    return false
}

export const setNestedObjectKey = (obj, path, value, action) => {
    if (path.length === 1) {
        if (action === 'delete') {
            if (obj) delete obj[path]
        } else {
            if (obj) obj[path] = value
        }

        return
    }

    return setNestedObjectKey(obj[path[0]], path.slice(1), value, action)
}

export const getStatus = (status) => {
    if (status === 'APPROVED') return 'success'
    if (['CLOSED', 'CANCELED', 'REJECTED'].indexOf(status) >= 0) return 'rejected'

    return 'pending'
}

// A function to compare if two arrays have the same elements regardless of their order.
// WARNING: Will return bad changes if the compared values are objects or arrays.
// This only works with simple value types: Strings, numbers, bools, etc.
export const ignoreOrderCompare = (a, b) => {
    if (a.length !== b.length) return false

    const elements = new Set([...a, ...b])

    for (const x of elements) {
        if (
            x != null && (
                x instanceof Array ||
                Object.getPrototypeOf(a) === Object.prototype ||
                x instanceof Map ||
                x instanceof Set
            )
        ) {
            const e = new IgnoreOrderCompareError(
                { error: 'ignoreOrderCompare called on an object that contains something that can\'t be shallow compared' },
            )
            throw e
        }

        const count1 = a.filter((e) => e === x).length
        const count2 = b.filter((e) => e === x).length

        if (count1 !== count2) return false
    }
    return true
}

export class IgnoreOrderCompareError extends Error {
    constructor(params) {
        super(params)
        const { error } = params

        this.name = 'IgnoreOrderCompareError'
        this.message = `Encountered a problem producing a comparison: ${error}`
    }

    toJSON() {
        return this.message
    }
}

export const reduceLoadingState = (results) => {
    if (!Array.isArray(results)) return false
    if (results.length === 0) return false

    const loadingStates = areAll(results, ['isLoading', 'isFetching', 'isRefetching', 'isInitialLoading'], false)

    const loading = !Object.values(loadingStates).every((v) => v)

    return loading
}

export const useInvalidateQuery = (queryKey) => {
    const queryClient = useQueryClient()

    return () => queryClient.invalidateQueries({ queryKey })
}

export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)

        return () => clearTimeout(handler)
    }, [value, delay])

    return debouncedValue
}

export const useDebounceObject = (values, delay = 1000, skipKeys = []) => {
    const [skipDebounce, setSkipDebounce] = useState(false)
    const [debouncedValues, setDebouncedValues] = useState(values)

    const debouncedKeys = Object.keys(values).filter((key) => !skipKeys.includes(key))
    const debouncedUpdate = debouncedKeys.map((key) => values[key])
    const immediateUpdate = skipKeys.map((key) => values[key])

    useEffect(() => {
        if (skipDebounce) {
            setDebouncedValues(values)
            setSkipDebounce(false)
        } else {
            const handler = setTimeout(() => {
                setDebouncedValues(values)
            }, delay)

            return () => clearTimeout(handler)
        }
    }, [...debouncedUpdate, delay])

    useEffect(() => {
        setDebouncedValues(values)
    }, [...immediateUpdate, delay])

    const setPreventDebounce = (state = true) => {
        setSkipDebounce(state)
    }

    return [
        debouncedValues,
        setPreventDebounce,
    ]
}

export const useDisablerTimeout = (timeout) => {
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        const disabler = setTimeout(() => setDisabled(false), timeout)

        return () => clearTimeout(disabler)
    }, [])

    return disabled
}
