import { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import Loading from 'components/Loading'
import { ALLOWED, DENIED } from 'features/authorization/permissions'
import { useCheckPermission } from 'features/authorization/hooks/check_permission'

const Can = ({ permission, children }) => {
    const { allowed, checkPermission } = useCheckPermission()

    useEffect(() => {
        checkPermission(permission)
    }, [permission])

    if (allowed === ALLOWED) return children

    if (allowed === DENIED) {
        return (
            <Box sx={{ textAlign: 'center', mt: 3 }}>
                <Typography variant='h2'>{'Ah, ah, ah. You didn\'t say the magic word.'}</Typography>
                <img src='/ahahah.gif' alt='permission denied' title='Ah, ah ah' />
            </Box>
        )
    }

    return <Loading />
}

export default Can
