import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import { EventCountsTodayList } from 'features/velocity/events/components/EventCountsToday/List'
import useStyles from './styles'

const EventCounts = () => {
    const styles = useStyles()
    const navigate = useNavigate()

    return (
        <>
            <Button sx={styles.btn} variant='outlined' onClick={() => navigate(-1)}>
                Back
            </Button>

            <EventCountsTodayList />
        </>
    )
}

export default EventCounts
