import { Box, CircularProgress } from '@mui/material'
import classesFn from './styles'

export const Loading = ({ inline, absolute, withOverlay }) => {
    const styles = classesFn({ withOverlay })

    if (inline) {
        return (
            <Box data-testid='loading'>
                <CircularProgress size='25px' />
            </Box>
        )
    }

    if (absolute) {
        return (
            <Box data-testid='loading' sx={{ ...styles.absolute, ...styles.container }}>
                <CircularProgress size='50px' />
            </Box>
        )
    }

    return (
        <Box data-testid='loading' style={styles.container}>
            <CircularProgress size='50px' />
        </Box>
    )
}

export default Loading
