import { useQuery } from '@tanstack/react-query'
import { useQueryDefaults } from 'queryHelpers'
import {
    fetchEventDashboardCounts,
    fetchBacklogDeadletterCountsByDate,
    fetchEventDeadletterCountsByDate,
    fetchEventCountsByDate,
    fetchEventCountsToday,
} from '../services'

export const useQueryEventDashboardCounts = (queryArgs = {}) => {
    const queryKey = ['events', 'dashboard']

    return useQuery({
        queryKey,
        queryFn: () => fetchEventDashboardCounts(),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryBacklogDeadletterCountsByDate = (pageQuery, queryArgs = {}) => {
    const queryKey = ['events', 'backlog_deadletter_count', 'by_date', pageQuery]

    return useQuery({
        queryKey,
        queryFn: () => fetchBacklogDeadletterCountsByDate(pageQuery),
        keepPreviousData: true,
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryEventDeadletterCountsByDate = (pageQuery, queryArgs = {}) => {
    const queryKey = ['events', 'event_deadletter_count', 'by_date', pageQuery]

    return useQuery({
        queryKey,
        queryFn: () => fetchEventDeadletterCountsByDate(pageQuery),
        keepPreviousData: true,
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryEventCountsByDate = (pageQuery, queryArgs = {}) => {
    const queryKey = ['events', 'event_count', 'by_date', pageQuery]

    return useQuery({
        queryKey,
        queryFn: () => fetchEventCountsByDate(pageQuery),
        keepPreviousData: true,
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryEventCountsToday = (pageQuery, queryArgs = {}) => {
    const queryKey = ['errors', 'events_count', 'today', pageQuery]

    return useQuery({
        queryKey,
        queryFn: () => fetchEventCountsToday(pageQuery),
        keepPreviousData: true,
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}
