import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkHealth } from './reducers'

export const useHealthCheck = () => {
    const dispatch = useDispatch()

    const { success, took, db_connected } = useSelector((s) => s.healthCheck.data)
    const status = useSelector((s) => s.healthCheck.status)

    useEffect(() => {
        dispatch(checkHealth())
    }, [dispatch])

    return {
        status,
        success,
        db_connected: db_connected ? 'connected' : 'not connected',
        latency: took,
    }
}

export default useHealthCheck
