import { useState } from 'react'
import { client } from 'oxidauth'
import { usePageNotifications } from 'features/page_notifications/hooks/page_notifications'
import { ALLOWED, DENIED } from '../permissions'

export const useCheckPermission = () => {
    const [allowed, setAllowed] = useState(null)
    const { addNotification } = usePageNotifications()

    const checkPermission = async (permission) => {
        client.checkPermission(permission)
            .then((allow) => {
                allow ? setAllowed(ALLOWED) : setAllowed(DENIED)
            })
            .catch((error) => {
                addNotification({ type: 'error', message: 'Unable to verify your permissions. Please try again', id: 'permissionError' })
                setAllowed(null)
            })
    }

    return {
        allowed,
        checkPermission,
    }
}
