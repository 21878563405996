import { Link } from 'react-router-dom'
import { Button, Chip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { getStatus } from 'helpers'
import { colors } from '.'

export const CellLinkStyled = styled(Link)(({ theme }) => ({
    color: theme.palette.text.primary,
    textDecoration: 'none',
    cursor: 'pointer',
}))

export const SectionStyled = styled('div')(({ theme }) => ({
    width: '100%',
    padding: `${theme.spacing(2)} 0`,
    margin: `${theme.spacing(2)} 0`,
}))

export const DescriptionListStyled = styled('div')(({ theme, style }) => ({
    marginBottom: 'auto',
    '& dt': {
        opacity: '50%',
        textTransform: 'uppercase',
    },
    '& dd': {
        fontSize: '1.25rem',
        marginLeft: 0,
        padding: '2px 0 12px',
    },
    '& p': {
        margin: 0,
    },
    ...style,
}))

export const StatusChipStyled = styled(Chip, {
    shouldForwardProp: (prop) => prop !== 'status' && prop !== 'variant' && prop !== 'incomm' && prop !== 'active' && prop !== 'color',
})(({ status, active, incomm, variant, theme, color, style }) => {
    const small = variant === 'sm' || variant === 'icon'

    return {
        borderRadius: '6px',
        textAlign: 'center',
        fontSize: small ? 18 : 22,
        height: small ? 40 : 50,
        margin: small ? '0' : '0 0 15px 0',
        minWidth: variant === 'sm' ? 155 : 0,
        fontWeight: 600,
        textTransform: 'uppercase',
        backgroundColor: calculateBG({ color, incomm, theme, status, active }),
        color: status ? 'inherit' : theme.palette.iconText.main,
        '& > span': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
        },
        '& svg': {
            height: 24,
            margin: 'auto 5px',
        },
        '& p': {
            margin: 'auto auto 6px',
        },
        ...style,
    }
})

export const LinkStyled = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    '&:link': {
        color: theme.palette.link.main,
    },
    '&:visited': {
        color: theme.palette.link.visited,
    },
}))

export const VizerLinkStyled = styled(Link)(() => ({
    color: colors.vizerCoral.main,
}))

export const ButtonStyled = styled(Button)(() => ({
    color: colors.white,
    fontSize: '1.125rem',
    fontWeight: 700,
    margin: '20px auto',
    padding: '10px 20px',
    textTransform: 'none',
    width: '100%',
}))

const calculateBG = ({ color, incomm, theme, status, active }) => {
    if (color) return color

    if (incomm) {
        const reducedStatus = getStatus(status)
        if (reducedStatus === 'success') return theme.palette.success.light
        if (reducedStatus === 'rejected') return theme.palette.error.light
        return theme.palette.warning.light
    }

    return active ? theme.palette.success.light : colors.vizerCharcoal.light
}
