import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
} from '@mui/material'
import Cell from 'components/Cell'
import Row from 'components/Row'
import Filter from 'features/pagination_filtering/components/Filter'
import Pagination from 'features/pagination_filtering/components/Pagination'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import { useBacklogDeadletterCountsByDate } from 'features/velocity/events/hooks/backlog_deadletters'
import useStyles from './styles'

export const BacklogDeadletterCountsByDateList = () => {
    const styles = useStyles()

    const {
        connectionError,
        backlogDeadletters,
        pagination,
        errors,
        isError,
        isLoading,
        isFetching,
        isRefetching,
        isInitialLoading,
        setPage,
        pageState,
    } = useBacklogDeadletterCountsByDate()

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
                isInitialLoading={isInitialLoading}
            />

            <Typography variant='h1' align='center'>Backlog Deadletters</Typography>

            <Filter
                pageState={pageState}
                pagination={pagination}
                setPage={setPage}
                disabled={isFetching}
            />

            <TableContainer component={Paper}>
                <Table aria-label='simple table' data-cy='table'>
                    <TableHead data-cy='head'>
                        <TableRow>
                            <TableCell align='center'>Count</TableCell>
                            <TableCell align='center'>Created At</TableCell>
                            <TableCell align='center'>Error</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-cy='body'>
                        {backlogDeadletters.map(({ count, created_at, error }) => (
                            <Row
                                key={`${created_at}-${error}`}
                                sx={styles.row}
                            >
                                <Cell scope='backlog deadletter' align='center'>{count}</Cell>
                                <Cell align='center'>{created_at}</Cell>
                                <Cell align='center'>{error}</Cell>
                            </Row>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {backlogDeadletters.length === 0 &&
                <Typography sx={styles.noResults} variant='h3' align='center'>No backlog deadletters found.</Typography>
            }

            <Pagination
                pageState={pageState}
                pagination={pagination}
                setPage={setPage}
                disabled={isFetching}
            />
        </>
    )
}

export default BacklogDeadletterCountsByDateList
