import ApartmentIcon from '@mui/icons-material/Apartment'
import BugReportIcon from '@mui/icons-material/BugReport'
import GroupWorkIcon from '@mui/icons-material/GroupWork'
import FeedbackIcon from '@mui/icons-material/Feedback'
import PersonIcon from '@mui/icons-material/Person'
import ImportContactsIcon from '@mui/icons-material/ImportContacts'
import InfoIcon from '@mui/icons-material/Info'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import StoreIcon from '@mui/icons-material/Store'
import ChildCareIcon from '@mui/icons-material/ChildCare'
import BlockIcon from '@mui/icons-material/Block'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart'
import MailLockIcon from '@mui/icons-material/MailLock'
import PanToolIcon from '@mui/icons-material/PanTool'
import {
    ADMIN_USERS_LIST, CLIENT_PORTAL_USERS_LIST, DEV,
} from 'features/authorization/permissions'
import { isProduction } from 'helpers'

const velocityProdNavList = [
    {
        icon: <LocalOfferIcon />,
        path: '/velocity/offers',
        text: 'Offers',
    },
    {
        icon: <GroupWorkIcon />,
        path: '/velocity/offer_partners',
        text: 'Offer Partners',
    },
    {
        icon: <StoreIcon />,
        path: '/velocity/retailers',
        text: 'Retailers',
    },
    {
        icon: <ApartmentIcon />,
        path: '/brands',
        text: 'Brands',
    },
    {
        icon: <ChildCareIcon />,
        text: 'Users',
        children: [
            {
                icon: <PersonIcon />,
                path: '/admin/users',
                text: 'Admin Users',
                permission: ADMIN_USERS_LIST,
            },
            {
                icon: <ChildCareIcon />,
                path: '/velocity/client_portal/users',
                text: 'Client Portal Users',
                permission: CLIENT_PORTAL_USERS_LIST,
            },
        ],
    },
    {
        icon: <PanToolIcon />,
        text: 'Fraud Mitigation',
        children: [
            {
                icon: <BlockIcon />,
                path: '/velocity/domains',
                text: 'Domains',
            },
            {
                icon: <MailLockIcon />,
                path: '/velocity/email_containment',
                text: 'Email Containment',
            },
        ],
    },
    {
        icon: <FeedbackIcon />,
        path: '/velocity/help_form_requests',
        text: 'Help Form Requests',
    },
    {
        icon: <InfoIcon />,
        path: '/about/stats',
        text: 'About',
    },
]

const velocityDevNavList = [
    {
        icon: <ImportContactsIcon />,
        path: '/velocity/catalogs',
        text: 'Catalogs',
    },
    {
        icon: <BugReportIcon />,
        path: '/velocity/debug',
        text: 'Debug',
        permission: DEV,
    },
    {
        icon: <MonitorHeartIcon />,
        path: '/velocity/events',
        text: 'Events',
        permission: DEV,
    },
]

const velocityNavList = [
    ...velocityProdNavList,
    ...(isProduction() ? [] : velocityDevNavList),
]

export default velocityNavList
