import { RESET_FILTERS, RESET_STATE, UPDATE_FILTER, UPDATE_STATE } from 'features/status'
import { currentDate, currentDateMinusDays } from 'helpers'
import { useReducer } from 'reducerHelpers'

export const paginationFilterInitialState = (state = {}) => {
    return {
        page: 1,
        per_page: 10,
        filters: {},
        dates: {},
        datesChanged: false,
        ...state,
    }
}

export const paginationFilterReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_STATE: {
            state[action.field] = action.value

            break
        }
        case UPDATE_FILTER: {
            if (action.value) {
                state.filters[action.field] = action.value
            } else {
                delete state.filters[action.field]
            }

            break
        }
        case 'UPDATE_DATE': {
            if (action.value) {
                state.dates[action.field] = action.value
                state.datesChanged = true
            } else {
                delete state.dates[action.field]
            }

            break
        }
        case 'RESET_DATES': {
            state.dates = {
                start_date: currentDateMinusDays(7),
                end_date: currentDate(),
            }
            state.datesChanged = false

            break
        }
        case RESET_FILTERS: {
            state.filters = {}

            break
        }
        case RESET_STATE: {
            return paginationFilterInitialState(state)
        }
        default: {
            return state
        }
    }
}

export const usePaginationFilterReducer = (state = {}) => {
    return useReducer(paginationFilterReducer, paginationFilterInitialState(state))
}
