import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
} from '@mui/material'
import Cell from 'components/Cell'
import Row from 'components/Row'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import Filter from 'features/pagination_filtering/components/Filter'
import Pagination from 'features/pagination_filtering/components/Pagination'
import useStyles from './styles'
import { isEmpty } from 'helpers'

export const EventCountsByDateList = (params) => {
    const styles = useStyles()

    const {
        connectionError,
        events,
        filters,
        dates,
        pagination,
        errors,
        isError,
        isLoading,
        isFetching,
        isRefetching,
        isInitialLoading,
        setPage,
        pageState,
        resetFilters,
        updateFilter,
        updateDate,
        disabled,
        resetFiltersDisabled,
        refresh,
    } = params

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
                isInitialLoading={isInitialLoading}
            />

            <Typography variant='h1' align='center'>Events By Date</Typography>

            <Filter
                pageState={pageState}
                filters={filters}
                dates={dates}
                pagination={pagination}
                setPage={setPage}
                updateFilter={updateFilter}
                updateDate={updateDate}
                resetFilters={resetFilters}
                disabled={disabled}
                resetFiltersDisabled={resetFiltersDisabled}
                refresh={refresh}
            />

            <TableContainer component={Paper}>
                <Table aria-label='simple table' data-cy='table'>
                    <TableHead data-cy='head'>
                        <TableRow>
                            <TableCell align='center'>Event Name</TableCell>
                            <TableCell align='center'>Recorded At</TableCell>
                            <TableCell align='center'>Event Deadletter Count / Request Deadletter Count</TableCell>
                            <TableCell align='center'>Event Count / Request Count</TableCell>
                            <TableCell align='center'>Success Deadletter Count</TableCell>
                            <TableCell align='center'>Success Count</TableCell>
                            <TableCell align='center'>Error Deadletter Count</TableCell>
                            <TableCell align='center'>Error Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-cy='body'>
                        {events.map(({
                            name, request_deadletter_count, request_count, success_deadletter_count,
                            success_count, error_deadletter_count, error_count, recorded_at,
                        }) => (
                            <Row
                                key={`${name}-${recorded_at}`}
                                sx={styles.row}
                            >
                                <Cell scope='event' align='center'>{name}</Cell>
                                <Cell align='center'>{recorded_at}</Cell>
                                <Cell align='center'>{request_deadletter_count}</Cell>
                                <Cell align='center'>{request_count}</Cell>
                                <Cell align='center'>{isEmpty(success_deadletter_count) ? '-' : success_deadletter_count}</Cell>
                                <Cell align='center'>{isEmpty(success_count) ? '-' : success_count}</Cell>
                                <Cell align='center'>{isEmpty(error_deadletter_count) ? '-' : error_deadletter_count}</Cell>
                                <Cell align='center'>{isEmpty(error_count) ? '-' : error_count}</Cell>
                            </Row>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {events.length === 0 &&
                <Typography sx={styles.noResults} variant='h3' align='center'>No events found.</Typography>
            }

            <Pagination
                pageState={pageState}
                pagination={pagination}
                setPage={setPage}
                disabled={isFetching}
            />
        </>
    )
}

export default EventCountsByDateList
