import { eventsWatchdogUrl, _get } from 'utils'
import qs from 'qs'

export async function fetchEventDashboardCounts() {
    try {
        const url = await eventsWatchdogUrl('/dashboard')

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchBacklogDeadletterCountsByDate(pageQuery) {
    try {
        const url = await eventsWatchdogUrl(`/backlog_deadletters/counts_by_error_by_date?${qs.stringify(pageQuery)}`)

        return await _get(url)

    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchEventDeadletterCountsByDate(pageQuery) {
    try {
        const url = await eventsWatchdogUrl(`/event_deadletters/counts_by_event_by_error_by_date?${qs.stringify(pageQuery)}`)

        return await _get(url)

    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchEventCountsByDate(pageQuery) {
    try {
        const url = await eventsWatchdogUrl(`/events/counts_by_date?${qs.stringify(pageQuery)}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchEventCountsToday(pageQuery) {
    try {
        const url = await eventsWatchdogUrl(`/events/counts_today?${qs.stringify(pageQuery)}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}
