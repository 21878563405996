import { useEffect } from 'react'
import { useQueryEventCountsToday, useQueryEventCountsByDate } from 'features/velocity/events/queries'
import { currentDate, currentDateMinusDays, reduceQueries, useInvalidateQuery } from 'helpers'
import { usePaginationFilters } from 'features/pagination_filtering/hooks'

export const useEventCountsByDate = (state = {}) => {
    const {
        setPage,
        resetPage,
        updateFilter,
        updateDate,
        resetFilters,
        params,
        pageState,
        paginationQueryArgs,
        resetFiltersDisabled: disabled,
    } = usePaginationFilters({
        state,
    })
    const paginationParams = { per_page: params.per_page, page: params.page, filters: { ...params.filters, ...params.dates } }

    const {
        payloads: [eventsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryEventCountsByDate(paginationParams, paginationQueryArgs)],
    )

    const invalidateListQueries = useInvalidateQuery(['events', 'event_count', 'by_date'])

    const resetFiltersDisabled = isResults.isFetching || disabled

    const events = eventsPayload?.event_counts || []
    const pagination = eventsPayload?.pagination || {}
    const filters = eventsPayload?.filters || {}
    const dates = params?.dates

    useEffect(() => {
        resetPage(pagination.total_pages)
    }, [pagination.total_pages])

    useEffect(() => {
        updateDate({ field: 'start_date', value: currentDateMinusDays(7) })
        updateDate({ field: 'end_date', value: currentDate() })
    }, [])

    return {
        connectionError,
        success,
        events,
        errors,
        warnings,
        notices,
        pagination,
        filters,
        dates,
        refresh: invalidateListQueries,
        setPage,
        pageState,
        updateFilter,
        updateDate,
        resetFilters,
        resetFiltersDisabled,
        ...isResults,
    }
}

export const useEventCountsToday = (state = {}) => {
    const {
        setPage,
        resetPage,
        params,
        pageState,
        paginationQueryArgs,
    } = usePaginationFilters({ state })

    const {
        payloads: [eventsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryEventCountsToday(params, paginationQueryArgs)],
    )

    const invalidateListQueries = useInvalidateQuery(['events', 'event_counts', 'today'])

    const eventCounts = eventsPayload?.event_counts || []
    const pagination = eventsPayload?.pagination || {}

    useEffect(() => {
        resetPage(pagination.total_pages)
    }, [pagination.total_pages])

    return {
        connectionError,
        success,
        eventCounts,
        errors,
        warnings,
        notices,
        pagination,
        invalidateListQueries,
        setPage,
        pageState,
        ...isResults,
    }
}
