import { Link } from 'react-router-dom'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Button,
} from '@mui/material'
import Cell from 'components/Cell'
import Row from 'components/Row'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import Filter from 'features/pagination_filtering/components/Filter'
import Pagination from 'features/pagination_filtering/components/Pagination'
import { sanitizeDate } from 'helpers'
import useStyles from './styles'

export const BrandsList = ({
    connectionError, brands, errors, isError, isLoading,
    isFetching, isRefetching, isInitialLoading, pageState,
    pagination, setPage,
}) => {
    const styles = useStyles()

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
                isInitialLoading={isInitialLoading}
            />

            <Typography variant='h1' align='center'>Brands</Typography>

            <Link to={{ pathname: '/brands/new' }}>
                <Button
                    sx={styles.btn}
                    variant='contained'
                    color='secondary'
                    data-cy='new-brand-btn'
                >
                    New
                </Button>
            </Link>

            <Filter
                pageState={pageState}
                pagination={pagination}
                setPage={setPage}
                disabled={isFetching}
            />

            <TableContainer component={Paper}>
                <Table aria-label='simple table' data-cy='table'>
                    <TableHead data-cy='head'>
                        <TableRow>
                            <TableCell align='center'>ID</TableCell>
                            <TableCell align='center'>Name</TableCell>
                            <TableCell align='center'>Created At</TableCell>
                            <TableCell align='center'>Updated At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-cy='body'>
                        {brands.map(({ id, name, created_at, updated_at }) => (
                            <Row
                                key={id}
                                path={`/brands/${id}`}
                            >
                                <Cell component='th' scope='brand' align='center'>{id}</Cell>
                                <Cell align='center'>{name}</Cell>
                                <Cell align='center'>{sanitizeDate(created_at)}</Cell>
                                <Cell align='center'>{sanitizeDate(updated_at)}</Cell>
                            </Row>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {brands.length === 0 &&
                <Typography style={styles.noResults} variant='h3' align='center'>No content found matching your selection.</Typography>
            }

            <Pagination
                pageState={pageState}
                pagination={pagination}
                setPage={setPage}
                disabled={isFetching}
            />
        </>
    )
}

export default BrandsList
