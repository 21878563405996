import { useEffect } from 'react'
import { useQueryBrands } from 'features/brands/queries'
import { reduceQueries } from 'helpers'
import { usePaginationFilters } from 'features/pagination_filtering/hooks'

export const useBrands = (state = {}) => {
    const {
        setPage,
        resetPage,
        params,
        pageState,
        paginationQueryArgs,
    } = usePaginationFilters({ state })

    const {
        payloads: [brandsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryBrands(params, paginationQueryArgs)],
    )

    const brands = brandsPayload?.brands || []
    const pagination = brandsPayload?.pagination || {}

    useEffect(() => {
        resetPage(pagination.total_pages)
    }, [pagination.total_pages])

    return {
        connectionError,
        success,
        brands,
        errors,
        warnings,
        notices,
        setPage,
        pageState,
        pagination,
        ...isResults,
    }
}
