import { keyframes } from '@mui/system'
import { colors } from 'theme'
import incommIcon from 'assets/images/incommIcon.png'
import { HIGH, LOW, TESTING } from 'features/status'

const flicker = keyframes`
  from {
            opacity: 1;
  }
  to {
            opacity: 0.6;
  }
`

const useStyles = () => ({
    flicker: {
        animation: `${flicker} 2500ms infinite ease-in-out`,
        animationDirection: 'alternate',
        width: '100%',
    },
    pageHeader: {
        marginBottom: '30px',
    },
    btn: {
        fontWeight: 500,
        '& svg': {
            marginLeft: '10px',
        },
    },
    titleBar: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: { xs: 'wrap', lg: 'nowrap' },
        marginBottom: '20px',
        '& h1': {
            margin: 0,
            width: { xs: '100%', lg: 'auto' },
        },
    },
    headerButtons: {
        marginLeft: { xs: 0, lg: 'auto' },
        marginTop: { xs: '20px', lg: 0 },
        display: 'flex',
        alignItems: 'center',
        '& > button': {
            marginRight: '10px',
        },
    },
    buttonRow: {
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        justifyContent: 'flex-end',
        flexWrap: { xs: 'wrap', lg: 'nowrap' },
        marginTop: '20px',
        '& h1': {
            margin: 0,
        },
        '& a': {
            marginTop: { xs: '20px', lg: 'auto' },
            marginLeft: { xs: 0, lg: '20px' },
        },
    },
    link: {
        textDecoration: 'none',
        color: colors.black,
    },
    pageRow: {
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
    },
    pageColumn: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 15px',
        '&:nth-of-type(1)': {
            paddingLeft: { xs: '15px', lg: 0 },
            marginBottom: { xs: '20px', lg: 0 },
        },
        '&:nth-of-type(4)': {
            flexGrow: 1,
            paddingRight: 0,
        },
    },
    incommLogo: {
        height: 22,
        width: 24,
        background: `url('${incommIcon}')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        display: 'inline-block',
        margin: 'auto 5px',
        filter: (theme) => theme.palette.mode === 'light' ? 'brightness(0)' : 'none',
    },
    logoImage: {
        height: 50,
        objectFit: 'contain',
    },
})

export const calculateChipColor = (activity) => {
    if (activity === TESTING) return colors.warning.light

    if (activity === LOW) return colors.success.lighter

    if (activity === HIGH) return colors.success.light

    return
}

export default useStyles
