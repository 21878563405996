const useStyles = () => ({
    row: {
        cursor: 'default',
    },
    noResults: {
        padding: '20px 0',
    },
})

export default useStyles
