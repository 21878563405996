import { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Typography,
} from '@mui/material'
import AlarmIcon from '@mui/icons-material/Alarm'
import AlarmOffIcon from '@mui/icons-material/AlarmOff'
import LaunchIcon from '@mui/icons-material/Launch'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import { capitalize, lastXChar, sanitizeDate, isProduction } from 'helpers'
import { DescriptionListStyled, StatusChipStyled } from 'theme/styled_components'
import { HIGH, LOW, TESTING } from 'features/status'
import ImageWithModal from 'components/ImageWithModal'
import LabelWithLink from 'components/LabelWithLink'
import VelocityLogo from 'components/VelocityLogo'
import ImageModal from 'features/modals/components'
import useStyles, { calculateChipColor } from './styles'

export const OfferShowHeader = ({
    refetchOffer, offer, offerPublicLink, offerLandingPreviewLink,
    offerEngagePreviewLink, offerMockApproval, refetchDisabled,
    generateStripImages, generateStripImagesDisabled,
}) => {
    const [debounce, setDebounce] = useState(false)
    const styles = useStyles()
    const { id } = useParams()

    const {
        offer_partner,
        offer_partner_logo_original,
        brand,
        status,
        engage_status,
        live,
        activityLevel,
        name,
        strategy,
        image_url,
        user_identification_method,
        created_at,
        updated_at,
    } = offer || {}

    const { incomm_status, strategy: strategy_type } = strategy

    const formatAuthMethod = (method) => {
        if (method === 'sms') return method.toUpperCase()

        return capitalize(method)
    }

    const onMockApproval = () => {
        setDebounce(true)
        offerMockApproval()

        setTimeout(() => setDebounce(false), 4000)
    }

    return (
        <>
            <ImageModal />

            <Card sx={styles.pageHeader}>
                <CardContent>
                    <Box sx={styles.titleBar}>
                        <Typography variant='h1'>{name}</Typography>
                        <Box sx={styles.headerButtons}>
                            {strategy_type === 'incomm' &&
                                <>
                                    {!isProduction() && incomm_status !== 'APPROVED' &&
                                        <Button
                                            sx={styles.btn}
                                            variant='contained'
                                            color='secondary'
                                            data-cy='edit-btn'
                                            disabled={debounce}
                                            onClick={onMockApproval}
                                            title='Set InComm status to "Approved" (staging only)'
                                        >
                                            Mock Approval
                                        </Button>
                                    }

                                    <Button
                                        sx={styles.btn}
                                        variant='contained'
                                        color='secondary'
                                        data-cy='edit-btn'
                                        disabled={refetchDisabled}
                                        onClick={refetchOffer}
                                        title='Fetch latest offer data from InComm'
                                    >
                                        Fetch InComm Data
                                    </Button>

                                    <Button
                                        sx={styles.btn}
                                        variant='contained'
                                        color='secondary'
                                        data-cy='edit-btn'
                                        disabled={generateStripImagesDisabled}
                                        onClick={generateStripImages}
                                        title='Generate strip images for this offers retailers'
                                    >
                                        Generate Strip Images
                                    </Button>
                                </>
                            }

                            <Link style={styles.link} to={`/velocity/offers/${id}/edit`}>
                                <Button
                                    sx={styles.btn}
                                    variant='contained'
                                    color='secondary'
                                    data-cy='edit-btn'
                                >
                                    Edit
                                </Button>
                            </Link>
                        </Box>
                    </Box>

                    <Box sx={styles.pageRow}>
                        <Box sx={styles.pageColumn}>
                            <ImageWithModal
                                noSpacing
                                image={{
                                    url: image_url,
                                    alt: `${name}'s Image`,
                                    variant: 'banner',
                                }}
                            />
                        </Box>

                        <Box sx={styles.pageColumn}>
                            <Box sx={styles.flicker}>
                                <StatusChipStyled
                                    active={live}
                                    style={{ width: '100%' }}
                                    label={
                                        <>
                                            {live ? <AlarmIcon /> : <AlarmOffIcon />}
                                            <p>LIVE</p>
                                        </>
                                    }
                                />
                            </Box>
                            <StatusChipStyled
                                active={status === 'active'}
                                label={
                                    <>
                                        <VelocityLogo fill={'#fff'} size={20} />
                                        <p>Landing {status}</p>
                                    </>
                                }
                            />

                            <StatusChipStyled
                                active={engage_status === 'active'}
                                label={
                                    <>
                                        <VelocityLogo fill={'#fff'} size={20} />
                                        <p>ENGAGE {engage_status}</p>
                                    </>
                                }
                            />

                            {strategy?.incomm_status &&
                                <StatusChipStyled
                                    incomm
                                    status={strategy?.incomm_status}
                                    label={
                                        <>
                                            <Box sx={styles.incommLogo} />
                                            <p>{strategy?.incomm_status}</p>
                                        </>
                                    }
                                />
                            }

                            {activityLevel &&
                                <StatusChipStyled
                                    color={calculateChipColor(activityLevel)}
                                    label={
                                        <>
                                            <ActivityLevelIcon activity={activityLevel} />
                                            <p>{activityLevel}</p>
                                        </>
                                    }
                                />
                            }
                        </Box>

                        <Box sx={styles.pageColumn}>
                            <DescriptionListStyled>
                                <dt>
                                    <p>Brand</p>
                                </dt>
                                <dd>
                                    <LabelWithLink
                                        label={brand.name}
                                        path={`/brands/${brand.id}`}
                                        id={brand.id}
                                        emptyLabel={'None'}
                                    />
                                </dd>
                                <dt>
                                    <p>Offer Partner</p>
                                </dt>
                                <dd>
                                    <LabelWithLink
                                        label={offer_partner.name}
                                        path={`/velocity/offer_partners/${offer_partner.id}`}
                                        id={lastXChar(offer_partner.id, 8)}
                                        emptyLabel={'None'}
                                    />
                                </dd>
                                {offer_partner_logo_original &&
                                    <>
                                        <dt>
                                            <p>Offer Partner Logo Override</p>
                                        </dt>
                                        <dd>
                                            <CardMedia
                                                sx={styles.logoImage}
                                                component='img'
                                                alt={offer_partner.name}
                                                image={offer_partner_logo_original}
                                                title={offer_partner.name}
                                            />
                                        </dd>
                                    </>
                                }
                                <dt>
                                    <p>User Identification Method</p>
                                </dt>
                                <dd>
                                    <p>{formatAuthMethod(user_identification_method)}</p>
                                </dd>
                            </DescriptionListStyled>
                        </Box>

                        <Box sx={styles.pageColumn}>
                            <DescriptionListStyled>
                                <dt>
                                    <p>Created</p>
                                </dt>
                                <dd>
                                    <p>{sanitizeDate(created_at)}</p>
                                </dd>
                                <dt>
                                    <p>Updated</p>
                                </dt>
                                <dd>
                                    <p>{sanitizeDate(updated_at)}</p>
                                </dd>
                            </DescriptionListStyled>
                        </Box>
                    </Box>

                    <Box sx={styles.buttonRow}>
                        <a
                            href={offerPublicLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Button
                                sx={styles.btn}
                                variant='contained'
                                color='secondary'
                                data-cy='edit-btn'
                            >
                                View Offer
                                <LaunchIcon />
                            </Button>
                        </a>

                        <a
                            href={offerLandingPreviewLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Button
                                sx={styles.btn}
                                variant='contained'
                                color='secondary'
                            >
                                Preview Landing Page
                                <LaunchIcon />
                            </Button>
                        </a>
                        {strategy_type === 'incomm' &&
                            <a
                                href={offerEngagePreviewLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button
                                    sx={styles.btn}
                                    variant='contained'
                                    color='secondary'
                                >
                                    Preview Engage Page
                                    <LaunchIcon />
                                </Button>
                            </a>
                        }
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}

const ActivityLevelIcon = ({ activity }) => {
    if (activity === TESTING) return <QueryStatsIcon />

    if (activity === LOW) return <KeyboardArrowUpIcon />

    if (activity === HIGH) return <KeyboardDoubleArrowUpIcon />

    return <MoreHorizIcon />
}

export default OfferShowHeader
