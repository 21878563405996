import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
} from '@mui/material'
import Cell from 'components/Cell'
import Row from 'components/Row'
import Filter from 'features/pagination_filtering/components/Filter'
import Pagination from 'features/pagination_filtering/components/Pagination'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import { useEventCountsToday } from 'features/velocity/events/hooks/events'
import { isEmpty } from 'helpers'
import useStyles from './styles'

export const EventCountsTodayList = () => {
    const styles = useStyles()

    const {
        connectionError,
        eventCounts,
        pagination,
        errors,
        isError,
        isLoading,
        isFetching,
        isRefetching,
        isInitialLoading,
        setPage,
        pageState,
    } = useEventCountsToday()

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
                isInitialLoading={isInitialLoading}
            />

            <Typography variant='h1' align='center'>Event Counts Today</Typography>

            <Filter
                pageState={pageState}
                pagination={pagination}
                setPage={setPage}
                disabled={isFetching}
            />

            <TableContainer component={Paper}>
                <Table aria-label='simple table' data-cy='table'>
                    <TableHead data-cy='head'>
                        <TableRow>
                            <TableCell align='center'>Event</TableCell>
                            <TableCell align='center'>Request Deadletter Count</TableCell>
                            <TableCell align='center'>Request Count</TableCell>
                            <TableCell align='center'>Success Deadletter Count</TableCell>
                            <TableCell align='center'>Success Count</TableCell>
                            <TableCell align='center'>Error Deadletter Count</TableCell>
                            <TableCell align='center'>Error Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-cy='body'>
                        {eventCounts.map(({
                            name, request_deadletter_count, request_count, success_deadletter_count,
                            success_count, error_deadletter_count, error_count,
                        }) => (
                            <Row
                                key={name}
                                sx={styles.row}
                            >
                                <Cell scope='name' align='center'>{name}</Cell>
                                <Cell align='center'>{request_deadletter_count}</Cell>
                                <Cell align='center'>{request_count}</Cell>
                                <Cell align='center'>{isEmpty(success_deadletter_count) ? '_' : success_deadletter_count}</Cell>
                                <Cell align='center'>{isEmpty(success_count) ? '_' : success_count}</Cell>
                                <Cell align='center'>{isEmpty(error_deadletter_count) ? '_' : error_deadletter_count}</Cell>
                                <Cell align='center'>{isEmpty(error_count) ? '_' : error_count}</Cell>
                            </Row>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {eventCounts.length === 0 &&
                <Typography sx={styles.noResults} variant='h3' align='center'>No event counts found.</Typography>
            }

            <Pagination
                pageState={pageState}
                pagination={pagination}
                setPage={setPage}
                disabled={isFetching}
            />
        </>
    )
}

export default EventCountsTodayList