import qs from 'qs'
import { velocityUrl, _get } from 'utils'
import { fmtGraphDate } from './hooks/graph'

export async function fetchOfferActivityLevel(offerID) {
    try {
        const url = await velocityUrl(`/offers/queries/fetch_offer_activity_level/${offerID}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchOfferDashboardAnalytics(offerID, strategy) {
    try {
        const params = { offer_strategy: strategy }

        const url = await velocityUrl(`/offers/queries/fetch_offer_analytics/${offerID}/summaries?${qs.stringify(params, { skipNulls: true })}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchOfferGraphAnalytics(offerID, {
    offer_strategy, start_date, end_date, utm_source, utm_term, utm_campaign, utm_content, utm_medium,
}) {
    try {
        const params = { offer_strategy, start_date: fmtGraphDate(start_date), end_date: fmtGraphDate(end_date) }

        const filterParams = {
            ...params,
            utm_source,
            utm_term,
            utm_campaign,
            utm_content,
            utm_medium,
        }

        const url = await velocityUrl(`/offers/queries/fetch_offer_analytics/${offerID}/graphs?${qs.stringify(filterParams, { skipNulls: true })}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchOfferGraphsAnalyticsDefaultDates(offerID) {
    try {
        const url = await velocityUrl(`/offers/queries/fetch_offer_analytics_graphs_default_dates/${offerID}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}
