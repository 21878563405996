import { useEffect } from 'react'
import { reduceQueries, useInvalidateQuery } from 'helpers'
import { useQueryEventDeadletterCountsByDate } from 'features/velocity/events/queries'
import { usePaginationFilters } from 'features/pagination_filtering/hooks'

export const useEventDeadletterCountsByDate = (state = {}) => {
    const {
        setPage,
        resetPage,
        params,
        pageState,
        paginationQueryArgs,
    } = usePaginationFilters({ state })

    const {
        payloads: [eventsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryEventDeadletterCountsByDate(params, paginationQueryArgs)],
    )

    const invalidateListQueries = useInvalidateQuery(['events', 'event_deadletter_count', 'by_date'])

    const eventDeadletters = eventsPayload?.event_deadletter_counts || []
    const pagination = eventsPayload?.pagination || {}

    useEffect(() => {
        resetPage(pagination.total_pages)
    }, [pagination.total_pages])

    return {
        connectionError,
        success,
        eventDeadletters,
        errors,
        warnings,
        notices,
        pagination,
        invalidateListQueries,
        setPage,
        pageState,
        ...isResults,
    }
}
