import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import qs from 'qs'
import dayjs from 'dayjs'
import { dateTimeLocalToUtc, isEmpty, isPresent, useDebounceObject } from 'helpers'
import { RESET_FILTERS, UPDATE_FILTER, UPDATE_STATE, RESET_STATE } from 'features/status'
import { usePaginationFilterReducer } from 'features/pagination_filtering/reducers'

export const usePaginationFilters = ({ state = {}, debounceSkipKeys = [] }) => {
    const navigate = useNavigate()
    const { search } = useLocation()

    const [searchState, setSearch] = useState('')

    const [pageState, dispatch] = usePaginationFilterReducer(search ? { ...state, ...qs.parse(search.slice(1)) } : state)
    const [filterParams, setPreventDebounce] = useDebounceObject(pageState?.filters, 2000, debounceSkipKeys)
    const params = { ...pageState, filters: { ...filterParams } }

    const paginationQueryArgs = Number(pageState.page) !== 1 ? { staleTime: Infinity, cacheTime: Infinity, refetchInterval: Infinity } : {}
    const resetFiltersDisabled = Object.keys(pageState?.filters).length === 0 && isEmpty(searchState) && !pageState?.datesChanged

    const setPage = (_, value, field) => dispatch({ type: UPDATE_STATE, field, value })
    const resetPage = (value) => {
        if (value < pageState.page) setPage(null, 1, 'page')
    }
    const updateFilter = (_, value, field) => {
        dispatch({ type: UPDATE_FILTER, field, value })
    }

    const updateDate = ({ field, datetime, value }) => {
        const date = datetime && dayjs(datetime).isValid() ? datetime.toISOString().split('T')[0] : value

        dispatch({ type: 'UPDATE_DATE', field, value: date })
    }

    const updateSearch = (value) => {
        dispatch({ type: UPDATE_FILTER, field: 'search_query', value })
    }
    const resetFilters = () => {
        setPreventDebounce()
        setSearch('')
        dispatch({ type: RESET_FILTERS })
        dispatch({ type: 'RESET_DATES' })
    }
    const resetPaginationFilters = () => dispatch({ type: RESET_STATE })

    useEffect(() => {
        const search_params = qs.parse(search.split('?')[1])

        if (isPresent(search_params?.filters?.search_query) && isEmpty(pageState?.filters?.page_query)) {
            setSearch(search_params?.filters?.search_query)
        }
    }, [search])

    useEffect(() => {
        if (isEmpty(searchState) || searchState?.length >= 2) {
            updateSearch(searchState)
        }
    }, [searchState])

    useEffect(() => {
        const paginationParams = { per_page: pageState.per_page, page: pageState.page, filters: { ...filterParams, ...pageState.dates } }

        const searchString = qs.stringify({ ...state, ...paginationParams })

        navigate({ search: searchString }, { replace: true })
    }, [pageState, navigate])

    return {
        setPage,
        resetPage,
        updateFilter,
        updateDate,
        setSearch,
        search: searchState,
        resetFilters,
        resetPaginationFilters,
        pageState,
        resetFiltersDisabled,
        paginationQueryArgs,
        setPreventDebounce,
        params,
    }
}
