import { Link } from 'react-router-dom'
import {
    Box, Button, Container,
    Grid, Typography,
} from '@mui/material'
import ConnectionError from 'components/ConnectionError'
import IsLoading from 'components/IsLoading'
import Loading from 'components/Loading'
import EventCountBox from '../EventCountBox'
import useStyles from './styles'

export const Dashboard = ({ eventCounts, isLoading, isInitialLoading, connectionError, isError, errors }) => {
    const styles = useStyles()

    const {
        backlog_deadletters,
        event_deadletters,
    } = eventCounts || {}

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isInitialLoading={isInitialLoading}
            />

            <Container maxWidth='xl'>
                <Box sx={{ mb: 8, mt: 3 }}>
                    <Box sx={styles.titleBar}>
                        <Typography variant='h2' sx={styles.title}>Backlog Deadletter</Typography>

                        <Link style={styles.link} to={'/velocity/events/backlog_deadletter/counts_by_date'}>
                            <Button
                                sx={styles.btn}
                                variant='contained'
                                color='secondary'
                            >
                                Counts By Date
                            </Button>
                        </Link>
                    </Box>
                    <Grid
                        container
                        spacing={2.5}
                        sx={styles.errorCounts}
                    >
                        <EventCountBox
                            total={backlog_deadletters?.total_today}
                            subject='Total Today'
                            color='vizerCoral'
                            border='solid'
                        />

                        <EventCountBox
                            total={backlog_deadletters?.daily_average}
                            subject='Daily Average'
                            color='vizerCoral'
                        />

                        <EventCountBox
                            total={backlog_deadletters?.total_this_week}
                            subject='Total This Week'
                            color='vizerPeacock'
                            border='solid'
                            grow
                        />

                        <EventCountBox
                            total={backlog_deadletters?.weekly_average_last_six_months}
                            subject='Weekly Average'
                            suffix='Last 6 Months'
                            color='vizerPeacock'
                        />

                        <EventCountBox
                            total={backlog_deadletters?.weekly_average_all_time}
                            subject='Weekly Average'
                            suffix='All Time'
                            color='vizerPeacock'
                        />
                    </Grid>
                </Box>

                <Box sx={{ mb: 8 }}>
                    <Box sx={styles.titleBar}>
                        <Typography variant='h2' sx={styles.title}>Event Deadletter</Typography>

                        <Link style={styles.link} to={'/velocity/events/event_deadletter/counts_by_date'}>
                            <Button
                                sx={styles.btn}
                                variant='contained'
                                color='secondary'
                            >
                                Counts By Date
                            </Button>
                        </Link>
                    </Box>
                    <Grid
                        container
                        spacing={2.5}
                        sx={styles.errorCounts}
                    >
                        <EventCountBox
                            total={event_deadletters?.total_today}
                            subject='Total Today'
                            color='vizerCoral'
                            border='solid'
                        />

                        <EventCountBox
                            total={event_deadletters?.daily_average}
                            subject='Daily Average'
                            color='vizerCoral'
                        />

                        <EventCountBox
                            total={event_deadletters?.total_this_week}
                            subject='Total This Week'
                            color='vizerPeacock'
                            border='solid'
                            grow
                        />

                        <EventCountBox
                            total={event_deadletters?.weekly_average_last_six_months}
                            subject='Weekly Average'
                            suffix='Last 6 Months'
                            color='vizerPeacock'
                        />

                        <EventCountBox
                            total={event_deadletters?.weekly_average_all_time}
                            subject='Weekly Average'
                            suffix='All Time'
                            color='vizerPeacock'
                        />
                    </Grid>
                </Box>
                <Box sx={styles.otherEventButtons}>
                    <Link style={styles.link} to={'/velocity/events/event_counts/today'}>
                        <Button
                            sx={styles.btn}
                            variant='contained'
                            color='secondary'
                        >
                            Other Event Counts Today
                        </Button>
                    </Link>
                    <Link style={styles.link} to={'/velocity/events/event_counts/by_date'}>
                        <Button
                            sx={styles.btn}
                            variant='contained'
                            color='secondary'
                        >
                            Other Event Counts By Date
                        </Button>
                    </Link>
                </Box>
            </Container>
        </>
    )
}

export default Dashboard
