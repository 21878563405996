import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import BacklogDeadletterCountsByDateList from 'features/velocity/events/components/BacklogDeadletterCountsByDate/List'
import useStyles from './styles'

const BacklogDeadletterCountsByDate = () => {
    const styles = useStyles()
    const navigate = useNavigate()

    return (
        <>
            <Button sx={styles.btn} variant='outlined' onClick={() => navigate(-1)}>
                Back
            </Button>

            <BacklogDeadletterCountsByDateList />
        </>
    )
}

export default BacklogDeadletterCountsByDate
